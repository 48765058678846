<template>
  <div>
    <loading :show="loading.page">
      <filter-reports
          ref="filter"
          :enable="isEnableFilter"
          :filter.sync="filter"
          disable-default-supplier
          show-seasons-filter
          show-suppliers-filter
          @initFilter="initFilter()"
      />
      <b-card>
        <loading :show="loading.table && !loading.page">
          <b-row>
            <table-search
                ref="search"
                :fields-search="['sku']"
                @clear="$refs.table.refresh()"
                @click="$refs.table.refresh()"
            />
            <b-col cols="12">
              <b-table
                  ref="table"
                  :current-page="currentPage"
                  :empty-text="$i18n.t('table.no_data')"
                  :fields="fields"
                  :items="tableDataProvider"
                  :per-page="perPage"
                  responsive
                  show-empty
              >
                <template
                    #cell(sku)="data"
                >
                  <router-link
                      :to="{name: 'fotostudio-article',params: { sku: data.item.sku }}"
                  >
                    {{ data.item.sku }}
                  </router-link>
                </template>
              </b-table>
            </b-col>
            <table-pagination
                :current-page.sync="currentPage"
                :per-page.sync="perPage"
                :total-rows.sync="totalRows"
                :view-rows="items.length"
                @change-per-page="$refs.table.refresh()"
            />
          </b-row>
        </loading>
      </b-card>
    </loading>
  </div>
</template>
<script>
import FilterReports from "@/views/reports/components/FilterReports.vue";
import TablePagination from "@/views/reports/components/table/components/pagination.vue";
import DataService from "@/views/fotostudio/services/DataService";
import TableSearch from "@/views/reports/components/table/components/search.vue";

export default {
  components: {
    TableSearch,
    TablePagination,
    FilterReports,
  },
  data() {
    return {
      filter: null,
      enableFilter: false,
      loading: {
        page: false,
        table: false,
      },
      isInitFilter: false,
      items: [],
      perPage: 25,
      totalRows: 0,
      currentPage: 1,
      search: "",
    }
  },
  computed: {
    isEnableFilter() {
      return !this.loading.page && !this.loading.table
    },
    fields() {
      return [
        {
          key: 'sku',
          label: this.$i18n.t('fotostudio.table.fields.sku'),
          sortable: true
        },
        {
          key: 'supplier',
          label: this.$i18n.t('fotostudio.table.fields.supplier'),
          sortable: true
        },
        {
          key: 'season',
          label: this.$i18n.t('fotostudio.table.fields.season'),
          sortable: true
        },
        {
          key: 'importDate',
          label: this.$i18n.t('fotostudio.table.fields.importDate'),
          sortable: true,
        }
      ]
    },
  },
  watch: {
    loading: {
      handler() {
        if (!this.loading.table) {
          this.loading.page = false
        }
      },
      deep: true,
    },
    filter() {
      this.currentPage = 1;
      this.$refs.table.refresh()
    },
  },
  methods: {
    initFilter() {
      this.isInitFilter = true;
      this.$refs.table.refresh()
    },
    tableDataProvider() {
      if (!this.isInitFilter) return [];
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
      };
      let allParams = {...params, ...this.filter}
      if (!this.$refs.search.isEmpty()) {
        allParams = {...allParams, ...{search: this.$refs.search.getWord()}}
      }
      const service = new DataService()
      this.loading.table = true
      return service.getArticles(allParams).then(res => {
        const response = res.data.data
        this.items = response.data
        this.totalRows = response.total
        this.loading.table = false
        this.$refs.search.enable()
        return this.items
      })
    },
  }
}

</script>
